export const categories = [
  {
    id: 'location-find',
    name: 'Location Finding Service',
    transactionType: {
      process: 'sell-purchase',
      alias: 'sell-purchase/release-1',
      unitType: 'item',
    },
    // stockType: 'oneItem',
  },
];
